import { useEffect, useState } from "react";
import axios from "axios";

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        axios.get("http://127.0.0.1:8000/api/blogs")
            .then(response => setBlogs(response.data))
            .catch(error => console.error(error));
    }, []);

    return (
        <div>
            <h1>Blog Posts</h1>
            {blogs.map(blog => (
                <div key={blog.id}>
                    <h2>{blog.title}</h2>
                    <p>{blog.content}</p>
                </div>
            ))}
        </div>
    );
};

export default BlogList;
